<template>
    <div class="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div class="w-full max-w-md space-y-8">
            <div>
                <img class="mx-auto h-16 w-auto" :src="require('@/assets/img/png/logo.png')" alt="Logo" />
                <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">PV Majstro Management
                </h2>
            </div>
            <form class="mt-8 space-y-6" v-on:submit.prevent="login">
                <div
                    class="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                    <label for="email"
                        class="absolute -top-2 left-2 -mt-px inline-block bg-gray-100 px-1 text-xs font-medium text-gray-900">E-mailadres</label>
                    <input type="email" name="email" id="email"
                        class="block w-full border-0 p-0 bg-gray-100 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="adres@domein.com" v-model="user.email" required />
                </div>
                <div
                    class="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                    <label for="password"
                        class="absolute -top-2 left-2 -mt-px inline-block bg-gray-100 px-1 text-xs font-medium text-gray-900">Wachtwoord</label>
                    <input type="password" name="password" id="password"
                        class="block w-full border-0 p-0 bg-gray-100 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                        placeholder="wachtwoord" v-model="user.password" required />
                </div>
                <app-error-alert v-if="message" :message="message"></app-error-alert>
                <div v-if="loading" class="flex flex-col flex-wrap justify-center content-center gap-2">
                    <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dot-num="3" color="#7c3aed" />
                    <span class="sr-only">loading...</span>
                </div>
                <div>
                    <button type="submit" :disabled="loading"
                        class="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 disabled:bg-gray-200 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                            <LockClosedIcon class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                                aria-hidden="true" />
                        </span>
                        Inloggen
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { LockClosedIcon } from '@heroicons/vue/20/solid'
import { HollowDotsSpinner  } from 'epic-spinners'
import AppErrorAlert from '@/components/alerts/AppErrorAlert.vue';

export default {
    components: {
        LockClosedIcon,
        HollowDotsSpinner,
        AppErrorAlert
    },
    data() {
        return {
            loading: false,
            message: null,
            user: {
                email: '',
                password: ''
            }
        }
    },
    methods: {
        login() {
            this.message = null;
            this.loading = true;

            this.$store.dispatch("auth/login", this.user).then(
                () => {

                },
                (error) => {
                    this.loading = false;
                    this.message = error.response.data.message;
                }
            );
        },
    },
}
</script>
  