import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_BASE_URL + '/dashboard';

class DashboardService {
    getStats(year = null) {
        let url = API_URL;
    
        if (year) {
            url += "?year=" + year;
        }
    
        return axios.get(url, { headers: authHeader() });
    }
}

export default new DashboardService();