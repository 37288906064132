import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardView
  },
  {
    path: '/leden',
    name: 'Leden',
    component: () => import('../views/MembersView.vue')
  },
  {
    path: '/activiteiten',
    name: 'Activiteiten',
    component: () => import('../views/EventsView.vue')
  },
  {
    path: '/suggesties',
    name: 'Suggesties',
    component: () => import('../views/SuggestionsView.vue')
  },
  {
    path: '/paginas',
    name: 'Pagina beheer',
    component: () => import('../views/PagesView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/');
  } else {
    next();
  }
});

export default router
