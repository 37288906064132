<template>
    <div class="min-h-full">
        <div class="bg-gray-800 pb-32">
            <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
                <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div class="border-b border-gray-700">
                        <div class="flex h-16 items-center justify-between px-4 sm:px-0">
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <img class="h-12 w-12" :src="require('@/assets/img/png/logo.png')" alt="Logo" />
                                </div>
                                <div class="hidden md:block">
                                    <div class="ml-10 flex items-baseline space-x-4">
                                        <router-link v-for="item in navigation" :key="item.name" :to="item.href"
                                            class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                            :aria-current="item.current ? 'page' : undefined">{{
                                                item.name
                                            }}</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden md:block">
                                <div class="ml-4 flex items-center md:ml-6">
                                    <button type="button" disabled
                                        class="rounded-full bg-gray-800 p-1 text-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                        <span class="sr-only">View notifications</span>
                                        <BellIcon class="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <!-- Profile dropdown -->
                                    <Menu as="div" class="relative ml-3">
                                        <div>
                                            <MenuButton
                                                class="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                <span class="sr-only">Open user menu</span>
                                                <span
                                                    class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-pink-100">
                                                    <span class="font-medium leading-none text-pink-800">{{
                                                        user.firstName.charAt(0) + user.lastName.charAt(0)
                                                    }}</span>
                                                </span>
                                            </MenuButton>
                                        </div>
                                        <transition enter-active-class="transition ease-out duration-100"
                                            enter-from-class="transform opacity-0 scale-95"
                                            enter-to-class="transform opacity-100 scale-100"
                                            leave-active-class="transition ease-in duration-75"
                                            leave-from-class="transform opacity-100 scale-100"
                                            leave-to-class="transform opacity-0 scale-95">
                                            <MenuItems
                                                class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <MenuItem>
                                                <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 cursor-pointer"
                                                    @click.prevent="showProfile = true">
                                                    Profiel
                                                </a>
                                                </MenuItem>
                                                <MenuItem>
                                                <a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 cursor-pointer"
                                                    @click.prevent="logOut">
                                                    Uitloggen
                                                </a>
                                                </MenuItem>
                                            </MenuItems>
                                        </transition>
                                    </Menu>
                                    <div class="ml-3 flex flex-col">
                                        <div class="text-base font-medium leading-none text-white">
                                            {{ user.firstName + ' ' + user.lastName }}</div>
                                        <div class="text-sm font-medium leading-none text-gray-400">{{ user.email }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="-mr-2 flex md:hidden">
                                <!-- Mobile menu button -->
                                <DisclosureButton
                                    class="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                    <span class="sr-only">Open main menu</span>
                                    <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                                    <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                                </DisclosureButton>
                            </div>
                        </div>
                    </div>
                </div>

                <DisclosurePanel class="border-b border-gray-700 md:hidden">
                    <div class="space-y-1 px-2 py-3 sm:px-3">
                        <DisclosureButton v-for="item in navigation" :key="item.name" as="div">
                            <router-link :to="item.href"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                                :aria-current="item.current ? 'page' : undefined">{{ item.name }}
                            </router-link>
                        </DisclosureButton>
                    </div>
                    <div class="border-t border-gray-700 pt-4 pb-3">
                        <div class="flex items-center px-5">
                            <div class="flex-shrink-0">
                                <span
                                    class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-pink-100">
                                    <span class="font-medium leading-none text-pink-800">{{
                                        user.firstName.charAt(0) +
                                            user.lastName.charAt(0)
                                    }}</span>
                                </span>
                            </div>
                            <div class="ml-3">
                                <div class="text-base font-medium leading-none text-white">{{
                                    user.firstName + ' ' +
                                        user.lastName
                                }}</div>
                                <div class="text-sm font-medium leading-none text-gray-400">{{ user.email }}</div>
                            </div>
                            <button type="button"
                                class="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span class="sr-only">View notifications</span>
                                <BellIcon class="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div class="mt-3 space-y-1 px-2">
                            <DisclosureButton as="a" @click.prevent="showProfile = true"
                                class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 cursor-pointer hover:bg-gray-700 hover:text-white">
                                Profiel </DisclosureButton>
                            <DisclosureButton as="a" @click.prevent="logOut"
                                class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 cursor-pointer hover:bg-gray-700 hover:text-white">
                                Uitloggen </DisclosureButton>
                        </div>
                    </div>
                </DisclosurePanel>
            </Disclosure>
            <header class="py-10">
                <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h1 class="text-3xl font-bold tracking-tight text-white">{{ $route.name }}</h1>
                </div>
            </header>
        </div>

        <main class="-mt-32">
            <div class="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
                <div class="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
                    <router-view />
                </div>
            </div>
        </main>

        <app-profile-modal-vue :active="showProfile" @close="showProfile = false"></app-profile-modal-vue>
    </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import AppProfileModalVue from '@/components/profile/AppProfileModal.vue'

export default {
    components: {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        Bars3Icon,
        BellIcon,
        XMarkIcon,
        AppProfileModalVue
    },
    mounted() {
        this.setSessionTimer();
    },
    data() {
        return {
            user: JSON.parse(sessionStorage.getItem('user')),
            navigation: [
                { name: 'Dashboard', href: '/' },
                { name: 'Leden', href: 'leden' },
                { name: 'Activiteiten', href: 'activiteiten' },
                { name: 'Suggesties', href: 'suggesties' },
                { name: 'Pagina\'s', href: 'paginas'}
            ],
            sessionTime: {
                hoursLeft: 3,
                minutesLeft: 59,
                secondsLeft: 59,
            },
            showProfile: false,
        }
    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/');
        },
        setSessionTimer() {
            const end = new Date(JSON.parse(sessionStorage.getItem('exp') * 1000)).getTime();
            setInterval(() => {
                const now = new Date().getTime();
                let remainingTime = end - now;

                const second = 1000;
                const minute = second * 60;
                const hour = minute * 60;
                const day = hour * 24;

                this.sessionTime.hoursLeft = Math.trunc((remainingTime % day) / hour);
                this.sessionTime.minutesLeft = Math.trunc((remainingTime % hour) / minute);
                this.sessionTime.secondsLeft = Math.trunc((remainingTime % minute) / second);
            }, 1000)
        }
    }
}
</script>

<style scoped>
.router-link-active {
    background: #111827;
    color: #fff;
}
</style>