<template>
  <AuthView v-if="!loggedIn" />
  <HomeView v-if="loggedIn" />
</template>

<script>
import AuthView from './views/AuthView.vue';
import HomeView from './views/HomeView.vue';

export default {
  components: {
    AuthView,
    HomeView
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

#app {
  font-family: 'Montserrat', sans-serif;
}
</style>

