import axios from 'axios';
import jwt_decode from "jwt-decode";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        const accessToken = response.data.accessToken;
        if (accessToken) {
          sessionStorage.setItem('user', JSON.stringify(response.data));
          this.persistTokenExpiry(accessToken);
        }
      });
  }

  logout() {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('exp');
  }

  persistTokenExpiry(accessToken) {
    const decoded = jwt_decode(accessToken);
    if (decoded.exp) {
      sessionStorage.setItem('exp', JSON.stringify(decoded.exp));
    }
  }
}

export default new AuthService();