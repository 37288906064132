<template>
    <TransitionRoot as="template" :show="active">
        <Dialog as="div" class="relative z-10">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div class="sm:flex sm:items-start">
                                    <div
                                        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-pink-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <span class="font-medium leading-none text-pink-800">{{ user.firstName.charAt(0)
        +
        user.lastName.charAt(0)
}}</span>
                                    </div>
                                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                                            {{ user.firstName }}&apos;s profiel</DialogTitle>
                                        <div class="mt-2">
                                            <strong class="text-sm text-gray-700">Naam</strong>
                                            <p class="text-sm text-gray-500">{{ user.firstName + ' ' + user.lastName }}
                                            </p>
                                            <strong class="text-sm text-gray-700">E-mailadres</strong>
                                            <p class="text-sm text-gray-500">{{ user.email }}</p>
                                            <strong class="text-sm text-gray-700">App autoriteit</strong>
                                            <div class="mt-1 mb-1">
                                                <span
                                                    class="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">{{
        user.role
}}</span>
                                            </div>
                                            <strong class="text-sm text-gray-700">Token</strong>
                                            <p class="text-sm text-gray-500">{{ user.accessToken.substring(0, 20) }} ...
                                                {{ user.accessToken.substr(user.accessToken.length - 20) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button type="button"
                                    class="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    @click="$emit('close')" ref="cancelButtonRef">Sluiten</button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
    components: {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot
    },
    props: {
        active: {
            type: Boolean,
            default: false,
            required: true,
        }
    },
    data() {
        return {
            user: JSON.parse(sessionStorage.getItem('user'))
        }
    }
}
</script>

<style>

</style>