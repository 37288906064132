<template>
  <!-- Stats -->
  <div class="mb-8">
    <h3 class="text-lg font-medium leading-6 text-gray-900">Algemene statistieken</h3>

    <div class="mt-5">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Selecteer een jaartal</label>
        <select id="tabs" name="tabs" v-model="currentTab" v-on:change="optionChange()"
          class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 cursor-pointer">
          <option v-for="tab in tabs" :key="tab.value" :value="tab">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <nav class="flex space-x-4 cursor-pointer" aria-label="Tabs">
          <button type="button" v-for="tab in tabs" :key="tab.value" v-on:click="switchTab(tab)"
            :class="[tab.current ? (tab.value ? 'bg-purple-50 text-purple-700' : 'bg-rose-50 text-rose-700') : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium']">
            {{ tab.name }}
          </button>
        </nav>
      </div>
    </div>

    <dl v-if="!loading"
      class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
      <div v-for="item in stats" :key="item.type" class="px-4 py-5 sm:p-6">
        <dt v-if="item.type === statType.MemberCount" class="text-base font-normal text-gray-900">Leden</dt>
        <dt v-else-if="item.type === statType.RegistrationCount" class="text-base font-normal text-gray-900">Inschrijvingen</dt>
        <dt v-else-if="item.type === statType.SuggestionCount" class="text-base font-normal text-gray-900">Suggesties</dt>
        <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
            {{ item.count }}
            <div v-if="item.type === statType.RegistrationCount">
              <p class="ml-1 font-normal text-sm text-gray-500"> over <span class="font-semibold text-indigo-400">{{
                item.eventCount }}</span> inschrijfbare activiteiten </p>
            </div>
          </div>
        </dd>
      </div>
    </dl>
    <div v-if="loading" class="flex flex-col flex-wrap justify-center content-center gap-2">
      <orbit-spinner :animation-duration="1200" :size="55" color="#7c3aed" />
      Loading...
    </div>

  </div>

  <!-- Quick actions -->
  <div
    class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
    <div v-for="(action, actionIdx) in actions" :key="action.title"
      :class="[actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '', actionIdx === 1 ? 'sm:rounded-tr-lg' : '', actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '', actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '', 'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
      <div>
        <span :class="[action.iconBackground, action.iconForeground, 'rounded-lg inline-flex p-3 ring-4 ring-white']">
          <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <router-link :to="action.to" class="focus:outline-none">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true" />
            {{ action.title }}
          </router-link>
        </h3>
        <p class="mt-2 text-sm text-gray-500">{{ action.description }}</p>
      </div>
      <span class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path
            d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/vue/20/solid'
import {
  RocketLaunchIcon,
  UserGroupIcon,
  LightBulbIcon,
  DocumentIcon
} from '@heroicons/vue/24/outline'

import { OrbitSpinner } from 'epic-spinners'

import DashboardService from '@/services/dashboard.service';

import StatType from '@/enums/stat-type.enum';

export default {
  components: {
    OrbitSpinner,
    ArrowUpIcon,
    ArrowDownIcon
  },
  data() {
    return {
      loading: true,
      statType: StatType,
      stats: [],
      tabs: [
        { name: 'All time', value: null, current: false },
        { name: '2023', value: 2023, current: false },
        { name: '2024', value: 2024, current: true },
      ],
      currentTab: null,
      actions: [
        {
          title: 'Leden',
          to: '/leden',
          icon: UserGroupIcon,
          description: 'Vind hier een overzicht van alle leden. Voeg een lid toe, update de gegevens van een bestaand lid of verwijder een oud lid uit het systeem.',
          iconForeground: 'text-teal-700',
          iconBackground: 'bg-teal-50',
        },
        {
          title: 'Activiteiten',
          to: '/activiteiten',
          icon: RocketLaunchIcon,
          description: 'Vind hier een overzicht van alle actviteiten. Maak een activiteit aan, update een bestaande activiteit en zie welke leden zich hebben aangemeld.',
          iconForeground: 'text-purple-700',
          iconBackground: 'bg-purple-50',
        },
        {
          title: 'Suggesties',
          to: '/suggesties',
          icon: LightBulbIcon,
          description: 'Vind hier een overzicht van alle suggesties gedaan door leden.',
          iconForeground: 'text-sky-700',
          iconBackground: 'bg-sky-50',
        },
        {
          title: 'Pagina\'s',
          to: '/paginas',
          icon: DocumentIcon,
          description: 'Vind hier een overzicht van alle pagina\'s op de leden website en pas hiervan de inhoud aan.',
          iconForeground: 'text-rose-700',
          iconBackground: 'bg-rose-50',
        },
      ]
    }
  },
  mounted() {
    this.currentTab = this.tabs[2];
    this.getStats(this.currentTab.value);
  },
  methods: {
    switchTab(tab) {
      this.loading = true;

      const previousTab = this.currentTab;
      previousTab.current = false;

      tab.current = true;
      this.currentTab = tab;

      this.getStats(tab.value);
    },
    optionChange() {
      this.loading = true;
      this.getStats(this.currentTab.value);
    },
    getStats(year) {
      DashboardService.getStats(year).then(
        (res) => {
          this.stats = res.data.stats;
          this.loading = false;
        }, () => {
          this.loading = false;
        }
      )
    }
  }
}
</script>

<style></style>